import React from 'react';

import Layout from '../components/layout';
import BackButton from '../components/BackButton';
import SEO from '../components/seo';
import PrivacyPolicyText from '../termsOfService/privacy.mdx';

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <BackButton />
    <br />
    <br />
    <PrivacyPolicyText />
    <br />
    <br />
    <BackButton />
  </Layout>
);


export default PrivacyPolicy;
