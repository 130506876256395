import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="h1" components={components}>{`Privacy Policy`}</MDXTag>
      <MDXTag name="h2" components={components}>{`1. General`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1.1.`}</MDXTag>{` Your privacy is important to us. It is Kassel Labs' policy to respect your privacy regarding any information we may collect from you across our website, `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://kassellabs.io"
        }}>{`https://kassellabs.io`}</MDXTag>{`, and other sites we own and operate.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1.2.`}</MDXTag>{` We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1.3.`}</MDXTag>{` We don’t share any personally identifying information publicly or with third-parties, except when required to by law.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1.4.`}</MDXTag>{` Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1.5.`}</MDXTag>{` You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1.6.`}</MDXTag>{` Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`2. Data collected`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.1.`}</MDXTag>{` When you use our services we collect the data you inserted in to use in your customized creation.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.2.`}</MDXTag>{` The data can be a text for a custom intro creator, for instance, images and other contents you can send to us.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.3.`}</MDXTag>{` The data used in your creation will be stored anonymously indefinitely so you can share your creation indefinitely too.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.4.`}</MDXTag>{` The content published in the service is stored in our database and can only be identified by a unique key.
This key available in the URL.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.5.`}</MDXTag>{` The content of this service is publicly available to anyone who knows the URL.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.6.`}</MDXTag>{` You can not prevent others from accessing your content if it was published to the service.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.7.`}</MDXTag>{` We do not index any user content created without the author permission.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.8.`}</MDXTag>{` We do not store any personal information of the user other than the content inserted in our service, except in the request of the Download case.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.9.`}</MDXTag>{` If you want your content removed from the site, please contact us via email providing the link to your creation and the date and time you created it, including your timezone.
You can check that information on your browser history.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.10.`}</MDXTag>{` We do not share users' personal information with anyone.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`3. Data Collected on Download Request`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3.1.`}</MDXTag>{` When you request your creation to be downloaded we request your email so we can send the download link when it is ready.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3.2.`}</MDXTag>{` Your email will be associated with the content you have requested to download, even when not initially created by you.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3.3.`}</MDXTag>{` Anyone can request to download any created content if have access to it.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3.4.`}</MDXTag>{` As explained in the services, the download may not be available immediately, so we send it to your email when it's ready.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3.5.`}</MDXTag>{` We may sent messages related to our service to your email.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3.6.`}</MDXTag>{` We do not share your email address with third parties.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3.7.`}</MDXTag>{` You will have a link to download the video file from our servers. This file will be kept in our servers for one month.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`4. Third-parties`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4.1.`}</MDXTag>{` We use Google Analytics to improve the performance and user experience of our websites, with this tool we collect page views anonymously.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4.2.`}</MDXTag>{` We use Sentry to collect data when erros occurs in our applications. The error report may contains information about your browser and operational system that will be used only to investigate the cause of the error.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4.3.`}</MDXTag>{` We use PayPal and Stripe as payment gateways, which we only collect the user email to send the video and information about the render process. We don't store credit card details and we don't charge without the user action.`}</MDXTag>
      <br />
      <MDXTag name="p" components={components}>{`If you have any questions about our Privacy Policy, you can contact us via email: `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "mailto:privacy@kassellabs.io"
        }}>{`privacy@kassellabs.io`}</MDXTag></MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      